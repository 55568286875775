<template>
  <h2 class="mb-30">Statistics</h2>
  <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
  <div class="servicesPage__filterWrapper">
    <vue-multiselect
      class="servicesPage__select mr-25"
      :placeholder="$t('allService')"
      label="name"
      :show-labels="false"
      v-model="filterByServices"
      :options="servicesOptions"
    >
    </vue-multiselect>
    <base-button
      @click="filterBy('currentMonth', 'statistics')"
      :active="filter.statistics.currentMonth"
      :sort="true"
    >
      {{ $t("currentMonth") }}
    </base-button>
    <datepicker
      :locale="loginUser.language_code"
      :class="{
        'datepickerInputFilterClass-active': filter.statistics.customData,
      }"
      :enableTimePicker="false"
      inputClassName="datepickerInputFilterClass"
      class="datepickerFilterClass"
      v-model="statisticDataRange"
      @closed="setCustomDateRange()"
      format="dd/MM"
      :placeholder="$t('custom')"
      autoApply
      range
      utc
    />
    <div class="col-2 experts__filterReset" @click="resetFilter()">
      <reset-icon />
      {{ $t("resetFilter") }}
    </div>
  </div>
  <div class="row mb-30">
    <div class="col-lg-6">
      <div
        class="transactionsStatisticCart transactionsStatisticCart__gradient"
      >
        <div class="companyHomePage__statisticsDay">
          <data-formatted
            v-if="thisMonth"
            date-format="dd"
            :data="statisticData.start"
          />
          <data-formatted
            v-else
            date-format="dd MMM"
            :data="statisticData.start"
          />
          -
          <data-formatted date-format="dd " :data="statisticData.end" />
          <span class="companyHomePage__statisticsDay weight-400">
            <data-formatted
              date-format="MMM, yyyy:"
              :data="statisticData.end"
            />
          </span>
        </div>
        <div>
          <div
            v-if="statistics.current"
            class="transactionsStatisticCart__number transactionsStatisticCart__number-mb-6"
          >
            {{ statistics.current.count }}
            <small>{{ $t("appointments") }}</small>
          </div>
          <div
            v-if="statistics.current"
            class="transactionsStatisticCart__statistics"
          >
            <grow-statistics-icon
              :white="true"
              class="me-2"
              :class="{ 'icon-rotate': statistics.current.countDifference < 0 }"
            />
            {{ statistics.current.countDifference < 0 ? "" : "+" }}
            {{ statistics.current.countDifference }}% {{ $t("fromLastMonth") }}
          </div>
        </div>
        <div>
          <div
            v-if="statistics.current && statistics.current.coverageLimit"
            class="transactionsStatisticCart__progress"
          >
            €{{ statistics.current.coverageAmount
            }}<small v-if="!customDateRange"
              >/ €{{ statistics.current.coverageLimit }}
              {{ $t("spent") }}</small
            >
          </div>
          <div
            class="progress-bar blue stripes"
            :class="{ 'opacity-0': customDateRange }"
          >
            <span class="transition" :style="'width:' + currentProgress"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="transactionsStatisticCart">
        <div class="companyHomePage__statisticsDay">
          Estimate for
          <span class="weight-400">
            <data-formatted date-format="MMM" :data="new Date()" />
          </span>
          <span class="weight-400">
            <data-formatted date-format=", yyyy:" :data="new Date()" />
          </span>
        </div>
        <div>
          <div
            v-if="statistics.estimate"
            class="transactionsStatisticCart__number transactionsStatisticCart__number-mb-6"
          >
            {{ statistics.estimate.count }}
            <small>{{ $t("appointments") }}</small>
          </div>
          <div
            v-if="statistics.estimate"
            class="transactionsStatisticCart__statistics"
          >
            <grow-statistics-icon
              class="me-2"
              :class="{
                'icon-rotate': statistics.estimate.countDifference < 0,
              }"
            />
            {{ statistics.estimate.countDifference < 0 ? "" : "+" }}
            {{ statistics.estimate.countDifference }}% {{ $t("fromLastMonth") }}
          </div>
        </div>
        <div>
          <div
            v-if="statistics.estimate"
            class="transactionsStatisticCart__progress"
          >
            €{{ statistics.estimate.coverageAmount
            }}<small
              >/ €{{ statistics.estimate.coverageLimit }}
              {{ $t("willBeSpent") }}</small
            >
          </div>
          <div class="progress-bar blue stripes">
            <span
              class="transition"
              :style="'width:' + estimateProgress"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h2 class="mb-30">Participation statistics</h2>
  <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
  <div class="servicesPage__filterWrapper">
    <vue-multiselect
      openDirection="bottom"
      class="servicesPage__select mr-25"
      :placeholder="$t('allService')"
      label="name"
      :show-labels="false"
      :searchable="true"
      @select="setParticipationStatistics()"
      v-model="participationFilterByServices"
      :options="participationStatistics.services"
    >
    </vue-multiselect>
    <vue-multiselect
      openDirection="bottom"
      class="servicesPage__select mr-25"
      placeholder="All employees"
      label="fullName"
      track-by="fullName"
      :show-labels="false"
      :searchable="true"
      @select="setParticipationStatistics()"
      v-model="participationFilterByEmployee"
      :options="participationStatistics.employees"
    >
    </vue-multiselect>
    <base-button
      @click="filterBy('currentMonth', 'participation')"
      :active="filter.participation.currentMonth"
      :sort="true"
    >
      {{ $t("currentMonth") }}
    </base-button>
    <datepicker
      :locale="loginUser.language_code"
      :class="{
        'datepickerInputFilterClass-active': filter.participation.customData,
      }"
      :enableTimePicker="false"
      inputClassName="datepickerInputFilterClass"
      class="datepickerFilterClass"
      v-model="statisticDataRangeParticipation"
      @closed="
        setCustomDateRangeParticipation();
        filterBy('customData', 'participation');
      "
      format="dd/MM"
      :placeholder="$t('custom')"
      autoApply
      range
      utc
    />
    <div class="col-2 experts__filterReset" @click="resetParticipationFilter()">
      <reset-icon />
      {{ $t("resetFilter") }}
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div
        v-for="service in participationStatistics.services"
        :key="service.id"
      >
        <statistics-service-table :information="service" />
      </div>
    </div>
    <div class="col-lg-6">
      <div
        v-for="employee in participationStatistics.employees"
        :key="employee.id"
      >
        <statistics-employee-table :information="employee" />
      </div>
    </div>
  </div>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";
import GrowStatisticsIcon from "@/components/UI/icons/arrows/GrowStatisticsIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import Datepicker from "@vuepic/vue-datepicker";
import { format, isSameMonth, startOfMonth } from "date-fns";
import { mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";
import StatisticsServiceTable from "@/components/elements/statistics/StatisticsServiceTable";
import StatisticsEmployeeTable from "@/components/elements/statistics/StatisticsEmployeeTable";

export default {
  name: "StatisticsCompanyPage",
  components: {
    StatisticsEmployeeTable,
    StatisticsServiceTable,
    ResetIcon,
    BaseButton,
    GrowStatisticsIcon,
    DataFormatted,
    Datepicker,
    VueMultiselect,
  },
  data() {
    return {
      customDateRange: false,
      filterByServices: [],
      participationFilterByEmployee: [],
      participationFilterByServices: [],
      servicesOptions: [],
      employees: [],
      statistics: {},
      filter: {
        statistics: {
          currentMonth: true,
          customData: false,
        },
        participation: {
          currentMonth: true,
          customData: false,
        },
      },
      participationStatistics: {
        employees: [],
        services: [],
      },
      statisticDataRange: [],
      statisticDataRangeParticipation: {
        start: startOfMonth(new Date()),
        end: new Date(),
      },
      statisticData: {
        start: startOfMonth(new Date()),
        end: new Date(),
      },
    };
  },
  async mounted() {
    this.statistics = await this.getStatistics();
    this.servicesOptions = await this.getServices();
    this.participationStatistics = await this.getParticipationStatistics();
  },
  methods: {
    filterBy(key, type) {
      for (let i in this.filter[type]) {
        this.filter[type][i] = key === i;
      }
      if (key === "currentMonth" && type === "statistics") {
        this.setCurrentMonth();
      }
      if (key === "currentMonth" && type === "participation") {
        this.setCurrentMonthParticipation();
      }
    },
    resetFilter() {
      this.statisticDataRange = {
        start: startOfMonth(new Date()),
        end: new Date(),
      };
      this.filterBy("currentMonth", "statistics");
      this.filterByServices = null;
    },
    async setStatistics() {
      this.statistics = await this.getStatistics();
    },
    async setParticipationStatistics() {
      this.participationStatistics = await this.getParticipationStatistics();
    },
    async resetParticipationFilter() {
      this.participationFilterByServices = [];
      this.participationFilterByEmployee = [];
      this.statisticDataRangeParticipation = {
        start: startOfMonth(new Date()),
        end: new Date(),
      };
      this.filterBy("currentMonth", "participation");
    },
    async setCurrentMonth() {
      this.statisticData.start = startOfMonth(new Date());
      this.statisticData.end = new Date();
      this.statisticDataRange = [];
      this.customDateRange = false;
      await this.setStatistics();
    },
    async setCurrentMonthParticipation() {
      this.statisticDataRangeParticipation.start = startOfMonth(new Date());
      this.statisticDataRangeParticipation.end = new Date();
      this.statisticDataRange = [];
      await this.setParticipationStatistics();
    },
    async setCustomDateRange() {
      this.statisticData.start = new Date(this.statisticDataRange[0]);
      this.statisticData.end = new Date(this.statisticDataRange[1]);
      this.customDateRange = !isSameMonth(
        new Date(this.statisticData.start),
        new Date(this.statisticData.end)
      );
      await this.filterBy("customData", "statistics");
      await this.setStatistics();
    },
    async setCustomDateRangeParticipation() {
      this.statisticDataRangeParticipation.start = new Date(
        this.statisticDataRangeParticipation[0]
      );
      this.statisticDataRangeParticipation.end = new Date(
        this.statisticDataRangeParticipation[1]
      );
      await this.setParticipationStatistics();
    },
    async getStatistics() {
      let service = this.filterByServices ? this.filterByServices.id : null;
      let start = this.statisticData.start
        ? format(this.statisticData.start, "yyyy-MM-dd") + "T00:00:00.000Z"
        : null;
      let end = this.statisticData.end
        ? format(this.statisticData.end, "yyyy-MM-dd") + "T00:00:00.000Z"
        : null;
      return this.$http
        .get("statistics/company", {
          params: {
            service: service,
            start: start,
            end: end,
          },
        })
        .then(({ data }) => {
          return (this.statistics = data.body);
        });
    },
    async getServices() {
      return this.$http.get("services", {}).then(({ data }) => {
        return data.body;
      });
    },
    async getParticipationStatistics() {
      let service = this.participationFilterByServices.id
        ? this.participationFilterByServices.id
        : null;
      let employee = this.participationFilterByEmployee.id
        ? this.participationFilterByEmployee.id
        : null;
      let start = this.statisticDataRangeParticipation.start
        ? format(this.statisticDataRangeParticipation.start, "yyyy-MM-dd") +
          "T00:00:00.000Z"
        : null;
      let end = this.statisticDataRangeParticipation.end
        ? format(this.statisticDataRangeParticipation.end, "yyyy-MM-dd") +
          "T00:00:00.000Z"
        : null;
      return this.$http
        .get("statistics/company-participation", {
          params: {
            service: service,
            employee: employee,
            start: start,
            end: end,
          },
        })
        .then(({ data }) => {
          data.body.employees.forEach((item) => {
            item.fullName = `${item.first_name} ${item.last_name}`;
          });
          return data.body;
        });
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    thisMonth() {
      return isSameMonth(
        new Date(this.statisticData.start),
        new Date(this.statisticData.end)
      );
    },
    currentProgress() {
      if (this.statistics.current) {
        let denominator =
          this.statistics.current.coverageLimit === 0
            ? 1
            : this.statistics.current.coverageLimit;
        let dividend = this.statistics.current.coverageAmount;
        let interest = (dividend / denominator) * 100;
        return interest + "%";
      }
    },
    estimateProgress() {
      if (this.statistics.estimate) {
        let denominator =
          this.statistics.estimate.coverageLimit === 0
            ? 1
            : this.statistics.estimate.coverageLimit;
        let dividend = this.statistics.estimate.coverageAmount;
        let interest = (dividend / denominator) * 100;
        return interest + "%";
      }
    },
  },
  watch: {
    filterByServices: {
      deep: true,
      handler() {
        this.setStatistics();
      },
    },
  },
};
</script>

<style></style>
