<template>
  <div class="row statisticsEmployeeTable__wrapper">
    <div class="col-lg-6">
      <div class="d-flex align-items-center">
        <avatar
          user-role="employees"
          :image-name="information.image"
          :user-code="information.code"
          size="100"
          width="60px"
          height="60px"
        />
        <div class="ml-10">
          <div class="statisticsServiceTable__bold">
            {{ information.fullName }}
          </div>
          <div class="statisticsServiceTable__opacity opacity-100">
            {{ information.position }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="statisticsServiceTable__bold text-end">
        €{{ information.coverageAmount }}
      </div>
      <div class="statisticsServiceTable__opacity text-end">
        {{ !!information.percentage ? information.percentage : 0 }}%
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";

export default {
  props: {
    information: Object,
  },
  name: "StatisticsEmployeeTable",
  components: { Avatar },
};
</script>

<style></style>
