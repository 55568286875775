<template>
  <h1>{{ $t("sidebarStatisticsBtn") }}</h1>
  <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
  <div class="servicesPage__filterWrapper">
    <vue-multiselect
      class="servicesPage__select mr-25"
      :placeholder="$t('allService')"
      label="name"
      :show-labels="false"
      v-model="filterByServices"
      :options="servicesOptions"
    >
    </vue-multiselect>
    <base-button
      @click="filterBy('currentMonth')"
      :active="filter.currentMonth"
      :sort="true"
    >
      {{ $t("currentMonth") }}
    </base-button>
    <datepicker
      :locale="loginUser.language_code"
      :class="{ 'datepickerInputFilterClass-active': filter.customData }"
      :enableTimePicker="false"
      inputClassName="datepickerInputFilterClass"
      class="datepickerFilterClass"
      v-model="statisticDataRange"
      @closed="
        setCustomDateRange();
        filterBy('customData');
      "
      format="dd/MM"
      :placeholder="$t('custom')"
      autoApply
      range
      utc
    />
    <div class="col-2 experts__filterReset" @click="resetFilter()">
      <reset-icon />
      {{ $t("resetFilter") }}
    </div>
  </div>
  <div class="row mb-50">
    <div class="col-12 col-lg-6">
      <div
        class="transactionsStatisticCart transactionsStatisticCart__gradient"
      >
        <h5>
          <data-formatted
            v-if="thisMonth"
            date-format="dd"
            :data="statisticData.start"
          />
          <data-formatted
            v-else
            date-format="dd MMM"
            :data="statisticData.start"
          />
          -
          <data-formatted
            date-format="dd MMM, yyyy:"
            :data="statisticData.end"
          />
        </h5>
        <div
          v-if="statistics.current"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="statisticsPage__countAppointment">
              {{ statistics.current.count }}
              <small>{{ $t("appointments") }}</small>
            </div>
            <div class="statisticsPage__countPercent">
              <div class="transactionsStatisticCart__statistics">
                <grow-statistics-icon
                  :white="true"
                  class="me-2"
                  :class="{
                    'icon-rotate': statistics.current.countDifference < 0,
                  }"
                />
                {{ statistics.current.countDifference < 0 ? "" : "+" }}
                {{ statistics.current.countDifference }}%
                {{ $t("fromLastMonth") }}
              </div>
            </div>
          </div>
          <div>
            <div class="statisticsPage__countAppointment">
              €{{ statistics.current.amount }}
            </div>
            <div class="statisticsPage__countPercent">
              <div class="transactionsStatisticCart__statistics">
                <grow-statistics-icon
                  :white="true"
                  class="me-2"
                  :class="{
                    'icon-rotate': statistics.current.countDifference < 0,
                  }"
                />
                {{ statistics.current.amountDifference < 0 ? "" : "+" }}
                {{ statistics.current.amountDifference }}%
                {{ $t("fromLastMonth") }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="statistics.current"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="statisticsPage__countAppointmentSuccessful">
              {{ durationHours(statistics.current.duration) }}<small>h</small>
              {{ durationMinutes(statistics.current.duration) }}<small>m</small>
            </div>
            <div class="statisticsPage__countPercent">
              {{ $t("appointmentsTime") }}
            </div>
          </div>
          <div>
            <div class="statisticsPage__countAppointmentSuccessful">
              {{ statistics.current.successCount }}
              <small>/{{ statistics.current.lmCount }}</small>
            </div>
            <div class="statisticsPage__countPercent">
              {{ $t("successfulAppointment") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="transactionsStatisticCart">
        <h5>
          {{ $t("estimateFor") }}
          <data-formatted date-format="MMM, yyyy:" :data="statisticData.end" />
        </h5>
        <div
          v-if="statistics.estimate"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="statisticsPage__countAppointment">
              {{ statistics.estimate.count }}
              <small>{{ $t("appointments") }}</small>
            </div>
            <div class="statisticsPage__countPercent">
              <div class="transactionsStatisticCart__statistics">
                <grow-statistics-icon
                  class="me-2"
                  :class="{
                    'icon-rotate': statistics.estimate.countDifference < 0,
                  }"
                />
                {{ statistics.estimate.countDifference < 0 ? "" : "+" }}
                {{ statistics.estimate.countDifference }}%
                {{ $t("fromLastMonth") }}
              </div>
            </div>
          </div>
          <div>
            <div class="statisticsPage__countAppointment">
              €{{ statistics.estimate.amount }}
            </div>
            <div class="statisticsPage__countPercent">
              <div class="transactionsStatisticCart__statistics">
                <grow-statistics-icon
                  class="me-2"
                  :class="{
                    'icon-rotate': statistics.estimate.countDifference < 0,
                  }"
                />
                {{ statistics.estimate.amountDifference < 0 ? "" : "+" }}
                {{ statistics.estimate.amountDifference }}%
                {{ $t("fromLastMonth") }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="statistics.estimate"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="statisticsPage__countAppointmentSuccessful">
              {{ durationHours(statistics.estimate.duration) }}<small>h</small>
              {{ durationMinutes(statistics.estimate.duration)
              }}<small>m</small>
            </div>
            <div class="statisticsPage__countPercent">
              {{ $t("appointmentsTime") }}
            </div>
          </div>
          <div>
            <div class="statisticsPage__countAppointmentSuccessful">
              {{ statistics.estimate.successCount }}
              <small>/{{ statistics.estimate.lmCount }}</small>
            </div>
            <div class="statisticsPage__countPercent">
              {{ $t("successfulAppointment") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!showAllAppointments"
    class="statisticsPage__read"
    @click="showAllAppointments = !showAllAppointments"
  >
    <div :class="{ 'statisticsPage__read-active': showAllAppointments }">
      <horizontal-arrow :appointment-icon="true" />
    </div>
    {{ $t("showAppointment") }}
  </div>
  <div v-if="showAllAppointments">
    <div class="d-flex justify-content-between align-items-center">
      <div
        class="animate__animated animate__slideInLeft d-flex align-items-center"
      >
        <h4 class="w-max-content mb-0">{{ $t("sidebarAppointmentsBtn") }}</h4>
        <div
          class="statisticsPage__read ml-15"
          @click="showAllAppointments = !showAllAppointments"
        >
          <div :class="{ 'statisticsPage__read-active': showAllAppointments }">
            <horizontal-arrow :appointment-icon="true" />
          </div>
          {{ $t("hide") }}
        </div>
      </div>
      <div class="animate__animated animate__slideInRight response-switcher">
        <div class="me-10">
          {{ $t("view") }}
        </div>
        <table-view-icon
          class="me-10 cursor-pointer"
          :active="view.table"
          @click="changeView('table')"
        />
        <cart-view-icon
          class="me-10 cursor-pointer"
          :active="view.cart"
          @click="changeView('cart')"
        />
      </div>
    </div>
    <div
      v-if="view.cart"
      class="animate__animated animate__fadeInUp row d-flex appointmentHistoryPage"
    >
      <div
        v-for="appointment in recentAppointments"
        class="col-6 col-lg-4 col-xxl-3"
      >
        <recent-appointments
          :is-expert="true"
          :recent-appointment="appointment"
        />
      </div>
    </div>
    <div
      v-if="view.table"
      class="animate__animated animate__fadeInUp appointmentHistoryPage"
    >
      <div class="statisticsPage__tableHeader row">
        <div class="col-4">
          <div>{{ $t("accountOverviewPageName") }}</div>
        </div>
        <div class="col-3">
          <div>{{ $t("employeeCalendarService") }}</div>
        </div>
        <div class="col-2">
          <div>{{ $t("filterByPosition") }}</div>
        </div>
        <div class="col-2">
          <div>{{ $t("price") }}</div>
        </div>
        <div class="col-1 text-end"></div>
      </div>
      <recent-appointments-expert-table
        v-for="recent in recentAppointments"
        :recent-appointment="recent"
      />
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import RecentAppointments from "@/components/elements/appointment/RecentAppointments";
import RecentAppointmentsExpertTable from "@/components/elements/appointment/RecentAppointmentsExpertTable";
import { format, isSameMonth, startOfMonth } from "date-fns";
import Datepicker from "@vuepic/vue-datepicker";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import GrowStatisticsIcon from "@/components/UI/icons/arrows/GrowStatisticsIcon";
import DurationTime from "@/components/components-helpers/DurationTime";

export default {
  name: "StatisticsExpertPage",
  components: {
    DurationTime,
    GrowStatisticsIcon,
    DataFormatted,
    RecentAppointmentsExpertTable,
    RecentAppointments,
    TableViewIcon,
    CartViewIcon,
    HorizontalArrow,
    ResetIcon,
    BaseButton,
    VueMultiselect,
    Datepicker,
  },
  data() {
    return {
      showAllAppointments: false,
      statisticData: { start: startOfMonth(new Date()), end: new Date() },
      recentAppointments: [],
      statisticDataRange: [],
      statistics: [],
      servicesOptions: [],
      filterByServices: [],
      filter: {
        currentMonth: true,
        customData: false,
      },
      view: {
        cart: false,
        table: true,
      },
      transactionsHistory: [],
    };
  },
  mounted() {
    this.response();
  },
  methods: {
    async response() {
      this.servicesOptions = await this.getServicesList();
      this.statistics = await this.getStatistics();
      this.recentAppointments = await this.getHistory();
    },
    async setCurrentMonth() {
      this.statisticData.start = startOfMonth(new Date());
      this.statisticData.end = new Date();
      this.statisticDataRange = [];
      this.statistics = await this.getStatistics();
    },
    async setCustomDateRange() {
      this.statisticData.start =
        format(new Date(this.statisticDataRange[0]), "yyyy-MM-dd") +
        "T00:00:00.000Z";
      this.statisticData.end =
        format(new Date(this.statisticDataRange[1]), "yyyy-MM-dd") +
        "T00:00:00.000Z";
      this.statistics = await this.getStatistics();
    },
    changeView(view) {
      for (let i in this.view) {
        this.view[i] = i === view;
      }
    },
    async resetFilter() {
      this.filterBy("currentMonth");
      this.filterByServices = null;
      this.statistics = await this.getStatistics();
    },
    filterBy(key) {
      for (let i in this.filter) {
        this.filter[i] = key === i;
      }
      if (key === "currentMonth") {
        this.setCurrentMonth();
      }
    },
    async getHistory() {
      return this.$http.get(`appointments/recent`).then(({ data }) => {
        return data.body;
      });
    },
    async getServicesList() {
      return this.$http.get(`services/expert`).then(({ data }) => {
        return data.body;
      });
    },
    async getStatistics() {
      return this.$http
        .get(`statistics/expert`, {
          params: {
            service: this.filterByServices ? this.filterByServices.id : null,
            start: this.statisticData.start,
            end: this.statisticData.end,
          },
        })
        .then(({ data }) => {
          return data.body;
        });
    },
    durationHours(data) {
      let hours = (Number(data) / 60).toFixed();
      return `${hours}`;
    },
    durationMinutes(data) {
      let minutes = (Number(data) % 60).toFixed();
      return `${minutes}`;
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    thisMonth() {
      return isSameMonth(
        new Date(this.statisticData.start),
        new Date(this.statisticData.end)
      );
    },
  },
  watch: {
    filterByServices: {
      deep: true,
      async handler() {
        this.statistics = await this.getStatistics();
      },
    },
  },
};
</script>

<style></style>
