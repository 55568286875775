<template>
  <div class="row statisticsServiceTable__wrapper">
    <div class="col-lg-6">
      <div class="statisticsServiceTable__bold">{{ information.name }}</div>
      <div class="statisticsServiceTable__opacity">
        {{ information.appsCount }} appointments
      </div>
    </div>
    <div class="col-lg-6">
      <div class="statisticsServiceTable__bold text-end">
        €{{ information.coverageAmount }}
      </div>
      <div class="statisticsServiceTable__opacity text-end">
        {{ !!information.percentage ? information.percentage : 0 }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: Object,
  },
  name: "StatisticsServiceTable",
};
</script>

<style></style>
