<template>
  <main-layout>
    <statistics-expert-page v-if="loginUser.role_id === 2" />
    <statistics-company-page v-if="loginUser.role_id === 3" />
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout";
import StatisticsExpertPage from "@/components/pages-templates/statistics/StatisticsExpertPage";
import StatisticsCompanyPage from "@/components/pages-templates/statistics/StatisticsCompanyPage";

export default {
  name: "StatisticsPage",
  components: { StatisticsCompanyPage, StatisticsExpertPage, MainLayout },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
