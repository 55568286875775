<template>
    <svg :class="{'statistic__icon': white}" width="20" height="20" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1 15L7.8 8.33333L11.2 11.6667L18 5M18 5H12.9M18 5V10" stroke="black"/>
    </svg>
</template>

<script>
export default {
    props: {
        white: false
    },
    name: "GrowStatisticsIcon"
}
</script>

<style>

</style>
